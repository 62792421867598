import Api from '../../../common/services/api';
import { createActivity } from '../utils/activity';
import { EConversationActivityTypes } from '../definitions';

export const CHAT_LEAVE_CONVERSATION = 'chat/LEAVE_CONVERSATION';

export default (conversationId) => async (dispatch, getState) => {
  const { loggedUser: { user: { id: loggedUserId } }, organisation: { selected } } = getState();

  Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/leave`);

  dispatch({
    type: CHAT_LEAVE_CONVERSATION,
    conversationId,
    activities: [
      createActivity(EConversationActivityTypes.USER_LEFT, {
        user_id: loggedUserId,
      }),
    ],
  });
};
