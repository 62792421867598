import { createSelector } from 'reselect';

import { formatUserStatus } from '@modules/core/selectors/logged-user';
import memoize from '../../../common/utils/memoize';
import * as messagesReducer from '../reducers/messages';
import { getConversationsRelatedUsers } from './conversations';
import { EMessageTypes } from '../definitions';

const getCurrentConversation = (state) => state.chat.conversations.current;
const shouldUpdate = (state) => state.chat.conversations.update;
const getEmojiReactions = (state) => state.chat.messages.emojiReactions;
const getCurrentConversationMessages = (state) => (state.chat.conversations.current
  ? state.chat.messages.conversations[state.chat.conversations.current]
  : []);
const getCurrentConversationPagination = (state) => (state.chat.conversations.current
  ? state.chat.pagination.messages[state.chat.conversations.current]
  : []);

export const messages = memoize.createSelector(
  [
    getCurrentConversation,
    getCurrentConversationMessages,
    shouldUpdate,
    getEmojiReactions
  ],
  (state, id) => messagesReducer.findByConversation(state, id),
);

export const getGroupConversationUsersStatus = createSelector(
  [messages, getConversationsRelatedUsers],
  (messageList, users) => {
    // console.log("debug messageList", messageList, "users", users);
    const messageUserIds = messageList.filter((message) => {
      return message.source_type === EMessageTypes.MESSAGE;
    }).map((message) => message.user.id);
    const statuses = messageUserIds.map((messageUserId) => {
      const user = users?.find((u) => u.id === messageUserId);
      return {
        id: messageUserId,
        status: user?.membership?.status
      };
    });
    // returns object with user id as key and raw status as value
    return Object.assign({}, ...statuses.map((s) => ({ [s.id]: s.status })));
  }
);

export const pagination = memoize.createSelector(
  [getCurrentConversationPagination],
  (_, data) => data || { loaded: false },
);

export const getMessagesMeta = (state, conversationId) => {
  return state?.chat?.messages?.meta?.[conversationId];
};

export const getUserStatus = createSelector(
  [
    getConversationsRelatedUsers,
    (_, participantId) => participantId
  ],
  (users, participantId) => {
    const user = users?.find((usr) => usr.id === participantId);
    const formattedStatus = formatUserStatus(user?.membership?.status);
    return formattedStatus;
  }
);
