import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ImageItemUser from '../../../../common/components/image-item/user';

const ParticipantComponent = React.memo(({ children, item, loggedUser }) => {
  const { t } = useTranslation();

  return (
    <div className="NewConversationModal__Item NewConversationModal__Item--disabled">
      <ImageItemUser
        item={{
          ...item,
          full_name: item.id === loggedUser.id ? t('chat:conversation_item_participant_yourself', { fullName: item.full_name }) : item.full_name,
        }}
      />
      {children}
    </div>
  );
});

export default ParticipantComponent;
