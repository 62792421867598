import React from 'react';
import * as R from 'ramda';
import { Trans } from 'react-i18next';
import ProfileImage from '@common/components/profile-image';
import Icon from '../../../../common/components/icon';
import ConversationForm from '../../forms/conversation';
import { EConversationActivityTypes } from '../../definitions';

const getIcon = (activity) => {
  switch (activity.activity_type) {
    case EConversationActivityTypes.USER_ADDED:
    case EConversationActivityTypes.ADMIN_ADDED:
      return 'group_add';
    case EConversationActivityTypes.USER_REMOVED:
    case EConversationActivityTypes.USER_LEFT:
      return 'close';
    case EConversationActivityTypes.CHANGED_NAME:
      return 'edit';
    default:
      return null;
  }
};

const handleOpenForm = () => {
  const el = document.querySelector('.Conversation__Header .ImageItem');

  if (el) el.click();
};

const ActivityComponent = ({ item, conversation, loggedUser }) => {
  const participants = R.sort((a) => (a.id === loggedUser.id ? 1 : -1), conversation.participants);

  if (item.activity_type === EConversationActivityTypes.CONVERSATION_CREATED) {
    return (
      <div className="ConversationActivity ConversationActivity--margin-bottom">
        <div className="ConversationActivity__Content">
          <div className="ConversationActivity__Content__Participants" onClick={handleOpenForm}>
            {participants.length > 3 && (
              <div className="ConversationActivity__Content__Participants__More ImagePlaceholder">
                +
                {' '}
                {participants.length - 2}
              </div>
            )}
            {participants.slice(0, participants.length === 3 ? 3 : 2).map((participant) => (
              <ProfileImage key={participant.id} size={60} user={participant} />
            ))}
          </div>
          <h2>{conversation.name}</h2>
          <h3>{item.text}</h3>
          {!conversation.has_left && conversation.is_admin && (
            <ConversationForm
              onlyParticipants
              loggedUser={loggedUser}
              conversation={conversation}
            >
              <a><Trans i18nKey="chat:activity_add_colleagues" /></a>
            </ConversationForm>
          )}
        </div>
      </div>
    );
  }

  if (!item.text) return null;

  const icon = getIcon(item);

  return (
    <div className="ConversationActivity">
      {icon && <Icon type={icon} />}
      <small>{item.text}</small>
    </div>
  );
};

export default ActivityComponent;
