import * as React from 'react';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import * as Alert from '../../../../common/services/alert';
import { Button } from '../../../../common/components/button';
import Icon from '../../../../common/components/icon';
import Modal from '../../../../common/components/modal';
import List from '../../../../common/components/list';
import SectionHeader from '../../../../common/components/section-header';
import Participant from '../../components/participant';
import ParticipantsList from '../../components/participants-list';

class NewConversationModal extends React.Component {
  constructor(props) {
    super();

    this.state = {
      step: 0,
      isGroup: false,
      name: '',
      groupParticipants: [],
      isPosting: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSetFocus = (ref) => (ref && ref.focus());
    this.handleToggleGroup = () => this.setState({ isGroup: !this.state.isGroup });
    this.handleNextStep = () => this.setState({ step: 1 });
    this.handleClear = () => this.setState({
      step: 0, isGroup: false, name: '', groupParticipants: [], isPosting: false,
    });
    this.handleToggleGroupParticipant = (user) => this.setState({
      groupParticipants: this.state.groupParticipants.some((u) => u.id === user.id)
        ? R.reject(R.propEq('id', user.id), this.state.groupParticipants)
        : R.append(user, this.state.groupParticipants),
    });
    this.handleGoBack = () => {
      if (this.state.step === 0) return this.setState({ isGroup: false });
      if (this.state.step === 1) return this.setState({ step: 0 });
    };
    this.handleRemoveGroupParticipant = (user) => this.setState({
      groupParticipants: R.reject(R.propEq('id', user.id), this.state.groupParticipants),
    });
    this.handleNewConversation = async (item) => {
      const { t } = this.props;

      try {
        await props.onNewConversation(item);

        this.handleClear();
      } catch (response) {
        Alert.forStatus(response.status_code, {
          warning: t('chat:new_conversation_form_warning_create_conversation'),
          error: t('chat:new_conversation_form_error_create_conversation'),
        });
      }
    };
  }

  static props;

  async handleSubmit() {
    const { t } = this.props;

    try {
      this.setState({ isPosting: true });

      await this.props.onNewGroupConversation({
        name: this.state.name,
        participant_ids: R.pluck('id', this.state.groupParticipants),
      });

      this.handleClear();
    } catch (response) {
      this.setState({ isPosting: false });

      Alert.forStatus(response.status_code, {
        warning: t('chat:new_conversation_form_warning_create_group'),
        error: t('chat:new_conversation_form_error_create_group'),
      });
    }
  }

  handleClose = () => {
    const { onClose } = this.props;
    const { isGroup } = this.state;

    if (isGroup) {
      this.handleGoBack();
      this.setState({ groupParticipants: [] });
    }

    onClose?.();
  };

  createContent() {
    const { step, isGroup, groupParticipants } = this.state;
    const { loggedUser, t } = this.props;

    switch (step) {
      case 0:
        return (
          // $FlowFixMe
          <ParticipantsList
            delay
            isGroup={isGroup}
            participants={groupParticipants}
            loggedUser={loggedUser}
            onToggleGroup={this.handleToggleGroup}
            onToggle={this.handleToggleGroupParticipant}
            onSelect={this.handleNewConversation}
          />
        );
      case 1:
        return (
          <List
            items={groupParticipants}
            header={(
              <>
                <div className="Form">
                  <input
                    ref={this.handleSetFocus}
                    placeholder={t('chat:new_conversation_form_name_placeholder')}
                    defaultValue={this.state.name}
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <SectionHeader
                  title={t('chat:new_conversation_form_participants_count', { count: groupParticipants.length })}
                />
              </>
            )}
            renderRow={({ item }) => (
              <Participant item={item} loggedUser={loggedUser}>
                <Icon onClick={() => this.handleRemoveGroupParticipant(item)} type="close" />
              </Participant>
            )}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const { step, isGroup, groupParticipants } = this.state;
    const {
      show,
      onShow,
      children,
      modalRef,
      t,
    } = this.props;

    return (
      <Modal
        show={show}
        fullWidthContent
        ref={modalRef}
        className="NewConversationForm modal--color-grey"
        size="small"
        title={isGroup ? t('chat:new_conversation_form_title_group') : t('chat:new_conversation_form_title')}
        content={this.createContent()}
        onBack={isGroup && this.handleGoBack}
        onClose={this.handleClose}
        onShow={onShow}
        footerNote={isGroup && t('chat:new_conversation_form_selected_participants_count', { count: groupParticipants.length })}
        footer={isGroup && (
          <>
            {step === 0 && (
              <Button
                type="primary"
                onClick={this.handleNextStep}
                disabled={this.state.groupParticipants.length === 0}
              >
                <Trans i18nKey="chat:new_conversation_form_next_step" />
              </Button>
            )}
            {step === 1 && (
              <Button
                isLoading={this.state.isPosting}
                type="primary"
                onClick={this.handleSubmit}
                disabled={this.state.groupParticipants.length === 0 || this.state.name.trim().length === 0}
              >
                <Trans i18nKey="chat:new_conversation_form_create" />
              </Button>
            )}
          </>
        )}
      >
        {children}
      </Modal>
    );
  }
}

export default withTranslation()(NewConversationModal);
