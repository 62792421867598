import * as React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SearchableList from '../../../../common/components/list/search';
import ImageItem from '../../../../common/components/image-item';
import Section from './section';

const ParticipantsListComponent = ({
  loggedUser,
  participants,
  isGroup,
  selectedNetworkId,
  delay,
  onToggle,
  onSelect,
  onToggleGroup,
  isDisabled,
  fetchNetworkUsers,
  fetchOrganisationUsers,
}) => {
  const { t } = useTranslation();

  return (
    <SearchableList
      key="overview"
      containerClassname="ParticipantsList"
      data={{
        onFetch: async (_, filter) => {
          // Prevent data from being loaded before modal animation is finished (looks weird)
          if (delay) await new Promise((resolve) => setTimeout(resolve, 250));

          const [organisationResponse, networkResponse] = await Promise.all([
            fetchOrganisationUsers(0, {
              query: filter && filter.query,
              sortBy: 'first_name',
              excludeCurrentNetwork: !!selectedNetworkId, // If you have no network selected fetch all users in organisation
              canChat: true,
            }, 6),
            selectedNetworkId && fetchNetworkUsers(0, { // Only when network is selected
              query: filter && filter.query,
              sortBy: 'first_name',
              canChat: true,
            }, 6),
          ]);

          const data = [{
            label: networkResponse ? t('chat:participants_list_other_networks') : t('chat:participants_list_organisation'),
            response: organisationResponse,
            onFetch: (newOffset) => fetchOrganisationUsers(newOffset, {
              query: filter && filter.query,
              sortBy: 'first_name',
              excludeCurrentNetwork: !!selectedNetworkId,
              canChat: true,
            }),
          }];

          // If you have a network selected
          if (networkResponse) {
            data.unshift({
              label: t('chat:participants_list_my_network'),
              response: networkResponse,
              onFetch: (newOffset) => fetchNetworkUsers(newOffset, {
                query: filter && filter.query,
                sortBy: 'first_name',
                canChat: true,
              }),
            });
          }

          return {
            data,
            meta: {
              pagination: {},
            },
          };
        },
      }}
      header={!isGroup && (
        <ImageItem
          className="NewConversationModal__Item"
          icon="group_add__filled"
          name={t('chat:participants_list_create_group')}
          onClick={onToggleGroup}
        />
      )}
      renderRow={Section}
      rowProps={{
        loggedUser,
        participants,
        isGroup,
        isDisabled,
        onToggle: isGroup && onToggle,
        onSelect: !isGroup && onSelect,
      }}
      placeholder={t('chat:participants_list_placeholder')}
    />
  );
};

const mapStateToProps = (state) => ({
  selectedNetworkId: state.network.selected,
});

const mapDispatchToProps = {
  fetchNetworkUsers: require('../../../network/actions/fetch-users').default,
  fetchOrganisationUsers: require('../../../organisation/actions').fetchUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantsListComponent);
