import Api from '../../../common/services/api';

export const CHAT_MESSAGE_SEEN = 'chat/MESSAGE_SEEN';

export default (conversationId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (!conversationId) return;

  Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/open`);

  dispatch({ type: CHAT_MESSAGE_SEEN, conversationId });
};
