import * as React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import AsyncList from '../../../../common/components/list/async';
import SectionHeader from '../../../../common/components/section-header';
import UserItem from './user';

const ParticipantsListSectionComponent = ({
  item: { label, response, onFetch }, loggedUser, isGroup, participants, onToggle, onSelect, isDisabled,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeader title={label} />
      <AsyncList
        disableInitialFetch
        data={{
          onFetch,
          cache: {
            items: response.data,
            pagination: response.meta.pagination,
          },
        }}
        renderRow={UserItem}
        rowProps={(item) => ({
          isGroup,
          loggedUser,
          disabled: isDisabled ? isDisabled(item) : false,
          checked: participants.some((participant) => item.id === participant.id),
          onClick: (selected) => (isGroup ? onToggle(item, selected) : onSelect(item)),
        })}
        placeholder={t('chat:participants_list_section_placeholder')}
        ShowMoreComponent={({ onShowMore }) => (
          <div className="NewConversationModal__Item NewConversationModal__Item--more" onClick={onShowMore}>
            <Trans i18nKey="chat:participants_list_section_load_more" />
          </div>
        )}
      />
    </>
  );
};

export default ParticipantsListSectionComponent;
