import * as fileUtil from '../../../../common/utils/file';

export default (values) => {
  const errors = {};

  if (!values?.text && values?.attachments?.length === 0 && !values?.gif) errors.text = true;
  if (fileUtil.isProcessingAttachments(values?.attachments || [])) errors.attachments = true;

  return errors;
};
