import PropTypes from 'prop-types';
import * as React from 'react';
import * as R from 'ramda';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import FileInput from '@common/components/form/inputs/file';
import GifInput from '@common/components/form/inputs/gif/gif';
import TextareaInput from '@common/components/form/inputs/textarea';
import { setFormFileAttachments } from '@common/components/form/utils';
import { ALL_ACCEPT } from '@common/components/form/inputs/file/utils';
import ImagePreview from '@common/components/file-preview/image';
import FilePreview from '@common/components/file-preview/file';
import Icon from '@common/components/icon';
import Permission from '@common/components/permission';
import Tooltip from '@common/components/tooltip';
import { Button } from '@common/components/button';
import { EComponentTypes } from '@common/definitions';
import { canPreviewAttachment } from '@common/utils/file';
import { getFilesFromClipboard } from '@common/utils/get-files-from-clipboard';
import postMessage from '../../actions/post-message';
import * as messagesReducer from '../../reducers/messages';
import validate from './validate';

const maxAmountOfFiles = 10;

class PostMessageForm extends React.Component {
  static propTypes = {
    conversationId: PropTypes.string.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    replyTo: PropTypes.object,
    formValues: PropTypes.object,
    invalid: PropTypes.bool,
    submitting: PropTypes.bool,
    disabled: PropTypes.bool,
    change: PropTypes.func,
    inputRef: PropTypes.func,
  };

  static defaultProps = {
    formValues: { attachments: [] },
  };

  constructor() {
    super();

    this.submitForm = this.submitForm.bind(this);
    this.handleRemoveAttachment = this.handleRemoveAttachment.bind(this);
    this.handleFocusInput = () => this.input && this.input.focus();
  }

  componentDidMount() {
    const { inputRef } = this.props;

    this.input = document.querySelector('.PostPrivateMessage__Input textarea');
    if (inputRef) inputRef(this.input);

    setTimeout(this.handleFocusInput, 500);
  }

  async submitForm(values, dispatch) {
    const { conversationId, reset } = this.props;

    await dispatch(postMessage(conversationId, values));

    reset();

    setTimeout(this.handleFocusInput, 0);
  }

  handleRemoveAttachment(index) {
    const { formValues, change } = this.props;

    const attachment = formValues.attachments[index];

    // Makes sure nothing happens once file has been processed
    if (attachment && attachment.cancelPromise) attachment.cancelPromise();

    formValues.attachments.splice(index, 1);

    change('attachments', formValues.attachments);
  }

  handleAddImageFromClipboard = (e) => {
    const { formValues, change, organisationId, t } = this.props;
    const files = getFilesFromClipboard(e);
    if (!R.isEmpty(files)) {
      e.preventDefault();
      const onChange = (newValue) => {
        const attachments = Array.isArray(newValue) ? newValue : [newValue];
        change('attachments', attachments);
      };
      setFormFileAttachments(files, formValues.attachments, organisationId, onChange, t, { processFile: true });
    }
  };

  render() {
    const {
      formValues, replyTo, submitting, disabled, invalid, handleSubmit, t,
    } = this.props;

    const { gif } = formValues;
    const [attachments, documents] = formValues && formValues.attachments.reduce((acc, attachment, index) => {
      attachment.index = index; // eslint-disable-line no-param-reassign

      if (canPreviewAttachment(attachment)) {
        acc[0].push(attachment);
      } else {
        acc[1].push(attachment);
      }

      return acc;
    }, [[], []]);

    return (
      <>
        {formValues.message_id && (
          <div className="PostPrivateMessage__Reply">
            <div className="PostPrivateMessage__Reply__Quote">
              <b>{replyTo.user.full_name}</b>
              {replyTo.text}
            </div>
            <Icon type="close" onClick={() => this.props.change('message_id', null)} />
          </div>
        )}
        <form className="Form PostPrivateMessage fs-mask" onSubmit={handleSubmit(this.submitForm)}>
          <FileInput
            multiple={maxAmountOfFiles}
            disabled={!!gif}
            processFile
            name="attachments"
            accept="image/*, video/*"
          >
            <Tooltip title={t('chat:post_message_attachment_photo_video')}>
              <Icon type="image__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <FileInput
            multiple={maxAmountOfFiles}
            disabled={!!gif}
            processFile
            name="attachments"
            accept={ALL_ACCEPT}
          >
            <Tooltip title={t('chat:post_message_attachment_document')}>
              <Icon type="document__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <Permission component={EComponentTypes.GIPHY}>
            <GifInput name="gif" disabled={!!gif || formValues.attachments.length > 0}>
              <Icon type="gif" className="PostPrivateMessage__Action" />
            </GifInput>
          </Permission>
          <div className="PostPrivateMessage__Input">
            <Field
              name="gif"
              component={({ input: { value, onChange } }) => value && (
                <div className="PostPrivateMessage__Attachments">
                  <ImagePreview
                    key={value.id}
                    file={value}
                    size={70}
                    onRemove={() => onChange(null)}
                  />
                </div>
              )}
            />
            {attachments.length > 0 && (
              <div className="PostPrivateMessage__Attachments">
                {attachments.map((attachment) => (
                  <ImagePreview
                    key={attachment.id || attachment.file_name}
                    file={attachment}
                    size={70}
                    onRemove={() => this.handleRemoveAttachment(attachment.index)}
                  />
                ))}
                <FileInput
                  multiple={maxAmountOfFiles}
                  processFile
                  name="attachments"
                  accept="image/*,video/*"
                >
                  <Icon type="add" />
                </FileInput>
              </div>
            )}
            {documents.length > 0 && (
              <div className="PostPrivateMessage__Documents">
                {documents.map((attachment) => (
                  <FilePreview
                    key={attachment.id || attachment.file_name}
                    file={attachment}
                    onRemove={() => this.handleRemoveAttachment(attachment.index)}
                  />
                ))}
              </div>
            )}
            <TextareaInput
              enableEmojis
              disableValidationFeedback
              name="text"
              placeholder={t('chat:post_message_form_text_placeholder')}
              onEnter={handleSubmit(this.submitForm)}
              disabled={submitting}
              onPaste={this.handleAddImageFromClipboard}
            />
          </div>
          <Button
            type="primary"
            buttonType="submit"
            icon="send"
            isLoading={submitting}
            disabled={disabled || invalid}
          />
        </form>
      </>
    );
  }
}

const mapStateToProps = (state, props) => {
  const formValues = (state.form[props.form] || {}).values || {};
  const replyTo = messagesReducer.findById(state, formValues.message_id);

  return {
    formValues,
    replyTo,
    organisationId: state.organisation.selected.id,
  };
};

const ConnectedPostMessageForm = connect(mapStateToProps)(PostMessageForm);

export default withTranslation()(reduxForm({
  destroyOnUnmount: false,
  initialValues: {
    text: '',
    attachments: [],
    message_id: null,
  },
  validate,
})(ConnectedPostMessageForm));
