import * as React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import UserStatusPreview from '@common/components/user-status/user-status-preview';
import { getFormattedUserStatus } from '@modules/chat/selectors/conversations';
import Dropdown from '../../../../common/components/dropdown';
import Icon from '../../../../common/components/icon';
import Confirm from '../../../../common/components/confirm-button';
import Participant from '../../components/participant';

const ConversationFormParticipantComponent = ({
  item, conversation, loggedUser, onOpenProfile, onRemove, onAddAdmin, onRemoveAdmin,
}) => {
  const { t } = useTranslation();

  const status = useSelector((state) => getFormattedUserStatus(state, item.id));

  return (
    <Participant key={item.id} item={item} loggedUser={loggedUser}>
      { status ? <UserStatusPreview status={status} /> : null }
      {item.id !== loggedUser.id && (
        <Dropdown
          alignRight
          id={`participant-${item.id}-actions`}
          toggle={(<Icon type="more_vert" />)}
        >
          {onOpenProfile && <Dropdown.Item onClick={() => onOpenProfile(item.id)}><Trans i18nKey="chat:conversation_participant_show_profile" /></Dropdown.Item>}
          {conversation.is_admin && (
            <>
              <Dropdown.Divider />
              {conversation.admin_ids.includes(item.id)
                ? (
                  <Confirm
                    key="remove"
                    title={t('chat:conversation_confirm_remove_admin', { fullName: item.full_name })}
                    onConfirm={() => onRemoveAdmin(item.id)}
                  >
                    <Dropdown.Item><Trans i18nKey="chat:conversation_remove_admin" /></Dropdown.Item>
                  </Confirm>
                )
                : (
                  <Confirm
                    key="add"
                    title={t('chat:conversation_confirm_add_admin')}
                    description={t('chat:conversation_confirm_add_admin_description', { fullName: item.full_name })}
                    onConfirm={() => onAddAdmin(item.id)}
                  >
                    <Dropdown.Item><Trans i18nKey="chat:conversation_add_admin" /></Dropdown.Item>
                  </Confirm>
                )}
              {onRemove && (
                <Confirm
                  title={t('chat:conversation_confirm_remove_participant', { fullName: item.full_name })}
                  onConfirm={() => onRemove(item.id)}
                >
                  <Dropdown.Item danger><Trans i18nKey="chat:conversation_remove_participant" /></Dropdown.Item>
                </Confirm>
              )}
            </>
          )}
        </Dropdown>
      )}
    </Participant>
  );
};

export default ConversationFormParticipantComponent;
