import * as R from 'ramda';
import Api from '../../../common/services/api';
// import type { Dispatch, StoreState } from '../../../common/types/store';
import { CHAT_REQUEST_CONVERSATIONS, CHAT_RECEIVE_CONVERSATIONS } from './index';

// We always load conversations, but we also load conversations when on the chat page
// To prevent multiple of the same calls, we queue API calls per organisation
const queue = {};

// export default (nextCursor: ?string, filter?: Object, limit: number = 25, clear: boolean) => async (dispatch: Dispatch, getState: () => StoreState) => {
export default (nextCursor, filter, limit = 25, clear) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  if (!selected) return;

  // If there is already a call in the queue for the same organisation
  if (queue[selected]) return queue[selected];

  if (clear) {
    dispatch({
      type: CHAT_REQUEST_CONVERSATIONS,
      clear,
    });
  }

  const promise = async () => {
    try {
      const query = Api.utils.toQuery({
        limit,
        cursor: nextCursor || true,
        archived: filter.archived === 'archived',
        q: filter.search,
      });

      const apiEndpoint = `/v3/organisations/${selected.id}/conversations?include=participants&${query}`;
      const result = await Api.get(apiEndpoint);

      return result;
      // eslint-disable-next-line no-useless-catch
    } catch (err) {
      throw err;
    } finally {
      delete queue[selected];
    }
  };

  // Add promise to queue
  queue[selected] = promise()
    .then((request) => {
      dispatch({
        type: CHAT_RECEIVE_CONVERSATIONS,
        items: request.data.map((conversation) => {
          if (!conversation.last_message) return conversation;

          const unread = conversation.last_message.user_id !== selected && conversation.last_message.seen === false;

          return R.assoc('unread', unread, conversation);
        }),
        related: request.meta.related,
        archive: !!filter.archived,
      });

      return request;
    });

  return queue[selected];
};
