import Api from '../../../common/services/api';
// import type { Dispatch, StoreState } from '../../../common/types/store';

export const CHAT_RECEIVE_MESSAGES = 'chat/RECEIVE_MESSAGES';

// export default (cursor: boolean = true, filter: void, limit: number = 25) => async (dispatch: Dispatch, getState: () => StoreState) => {
export default (cursor = true, _, limit = 25) => async (dispatch, getState) => {
  const { organisation: { selected }, ui: { chat: { selectedConversationId } } } = getState();

  const query = Api.utils.toQuery({
    limit,
    cursor,
  });

  const request = await Api
    .get(`/v3/organisations/${selected.id}/conversations/${selectedConversationId}/messages?${query}`);

  dispatch({
    type: CHAT_RECEIVE_MESSAGES,
    conversationId: selectedConversationId,
    items: request.data,
    meta: request.meta,
    related: request.meta.related,
  });

  return request;
};
