import moment from 'moment';
import i18n from '../../i18n';

export const createDateStamp = (value) => {
  const date = value instanceof moment ? value : moment(value);
  const today = moment().startOf('day');
  const diff = today.diff(date.clone().startOf('day'), 'days');

  if (diff === 0) {
    return i18n.t('common:utils_create_timestamp_today');
  } if (diff === 1) {
    return i18n.t('common:utils_create_timestamp_yesterday');
  } if (diff < 7) {
    return date.format('dddd');
  } if (date.year() === today.year()) {
    return date.format('D MMMM');
  }

  return date.format('D MMMM YYYY');
};

export const createTimeStamp = (datetime) => {
  const createdAt = moment(datetime);

  if (moment().isSame(createdAt, 'day')) return createdAt.format('HH:mm');

  return createDateStamp(datetime);
};

export const createLongTimestamp = (createdAtString) => {
  const createdAt = moment(createdAtString);
  let timeStamp;

  if (createdAt.isSame(moment(), 'day')) {
    timeStamp = createdAt.format('HH:mm');
  } else {
    timeStamp = createdAt.format(`DD MMMM [${i18n.t('common:utils_create_timestamp_at')}] HH:mm`);
  }

  return timeStamp;
};
