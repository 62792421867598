import React, { useState } from 'react';
import { Popup } from 'semantic-ui-react';

import { Icon } from '@common/components/icon';

import type { AllowedEmoji } from '@common/types/objects';

type EmojiReactionPickerProps = {
  allowedEmojis: AllowedEmoji[];
  onSelect?: (emoji: AllowedEmoji) => void;
};

export const EmojiReactionPicker = ({ allowedEmojis, onSelect }: EmojiReactionPickerProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleSelectEmoji = (emoji: AllowedEmoji) => {
    onSelect?.(emoji);
    setIsVisible(false);
  };

  return (
    <Popup
      className="EmojiReactionPicker"
      hideOnScroll
      on="click"
      open={isVisible}
      position="bottom right"
      offset={[10, 0]}
      onOpen={() => setIsVisible(true)}
      onClose={() => setIsVisible(false)}
      trigger={(
        <Icon
          className="EmojiReactionPicker__TriggerButton"
          type="insert_emoticon_reaction"
          role="button"
        />
      )}
      content={(
        <div className="EmojiReactionPicker__OptionsList" role="listbox">
          {allowedEmojis.map((emoji) => (
            <span
              key={emoji.short_name}
              role="option"
              className="EmojiReactionPicker__Option"
              onClick={() => handleSelectEmoji(emoji)}
            >
              {emoji.character}
            </span>
          ))}
        </div>
      )}
    />
  );
};
