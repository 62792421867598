import Api from '../../../common/services/api';

export const CHAT_TOGGLE_CONVERSATION_NOTIFICATIONS = 'chat/TOGGLE_CONVERSATION_NOTIFICATIONS';

export default (conversationId, mute) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/mute`, {
    mute_conversation: mute,
  });

  return dispatch({
    type: CHAT_TOGGLE_CONVERSATION_NOTIFICATIONS,
    conversationId,
    muted: mute,
  });
};
