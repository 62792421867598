import * as React from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import { Trans, withTranslation } from 'react-i18next';
import moment from 'moment';
import Modal from '../../../../common/components/modal';
import { Button } from '../../../../common/components/button';
import SectionHeader from '../../../../common/components/section-header';
import ImageItem from '../../../../common/components/image-item';
import Confirm from '../../../../common/components/confirm-button';
import List from '../../../../common/components/list';
import ParticipantsList from '../../components/participants-list';
import Participant from './participant';
import Form from './form';

class ConversationForm extends React.Component {
  constructor(props) {
    super();

    this.state = {
      isAddingParticipants: props.onlyParticipants || false,
      addedParticipants: [],
      posting: false,
    };

    this.setModalReference = (ref) => {
      if (props.modalRef) props.modalRef(ref);

      this.modal = ref;
    };
    this.handleAddParticipants = () => this.setState({ isAddingParticipants: true });
    this.handleBack = () => this.setState({ isAddingParticipants: false });
    this.handleRemove = (userId) => props.removeParticipant(props.conversation.id, userId);
    this.handleToggleGroupParticipant = (user, selected) => this.setState({
      addedParticipants: selected
        ? R.append(user, this.state.addedParticipants)
        : R.reject(R.propEq('id', user.id), this.state.addedParticipants),
    });
    this.handleLeave = () => {
      if (props.onLeave) props.onLeave(props.conversation.id);

      if (this.modal) this.modal.handleClose();
    };
    this.handleSaveParticipants = this.handleSaveParticipants.bind(this);
  }

  static props;
  modal;

  async handleSaveParticipants() {
    const { conversation, onlyParticipants, addParticipants } = this.props;

    this.setState({ posting: true });

    await addParticipants(conversation.id, this.state.addedParticipants);

    this.setState({
      isAddingParticipants: onlyParticipants || false,
      addedParticipants: [],
      posting: false,
    });

    if (onlyParticipants && this.modal) this.modal.handleClose();
  }

  render() {
    const { isAddingParticipants, addedParticipants } = this.state;
    const {
      children, loggedUser, conversation, onlyParticipants, onOpenProfile, onLeave, onAddAdmin, onRemoveAdmin, t,
    } = this.props;
    const { participants } = conversation;

    // conversation.is_admin = false;

    return (
      <Modal
        fullWidthContent
        ref={this.setModalReference}
        list={!isAddingParticipants}
        size="small"
        title={t('chat:conversation_group_information')}
        className="NewConversationForm modal--color-grey"
        content={isAddingParticipants ? (
          <>
            {/* $FlowFixMe */}
            <ParticipantsList
              isGroup
              delay={onlyParticipants}
              participants={R.concat(participants, addedParticipants)}
              loggedUser={loggedUser}
              onToggle={this.handleToggleGroupParticipant}
              isDisabled={(item) => !!R.find(R.propEq('id', item.id), conversation.participants)}
            />
          </>
        ) : (
          <>
            {conversation.is_admin ? ( // Editing group name
              <Form
                form={`conversation-${conversation.id}`}
                conversation={conversation}
                initialValues={{
                  name: conversation.name,
                  description: conversation.description,
                }}
              />
            ) : (
              <>
                <div className="ConversationForm__Image">
                  <div className="ConversationForm__Image__Container">
                    <img src={conversation.group_img || '/static/images/group_chat_placeholder.png'} alt="Group chat" />
                  </div>
                </div>
                <div className="ConversationForm__Title">
                  {conversation.actual_name || <div className="ConversationForm__Title__Placeholder"><Trans i18nKey="chat:conversation_form_title_placeholder" /></div>}
                </div>
                {conversation.description && (
                  <div className="ConversationForm__Description">{conversation.description}</div>
                )}
              </>
            )}
            {conversation.admins && conversation.admins.length > 0 && (
              <>
                <SectionHeader title={t('chat:conversation_form_admins', { count: conversation.admins.length })} />
                <List
                  items={R.sort((user, b) => {
                    if (user.id === loggedUser.id) return -1;

                    return user.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1;
                  }, conversation.admins)}
                  renderRow={Participant}
                  rowProps={{
                    loggedUser,
                    conversation,
                    onOpenProfile,
                    onRemoveAdmin,
                    onRemove: this.handleRemove,
                    isParticipantAdmin: true,
                  }}
                />
              </>
            )}
            <SectionHeader title={t('chat:conversation_form_participants_count', { count: participants.length })} />
            <List
              items={R.sort((user, b) => {
                // Sort your own account to the bottom
                if (user.id === loggedUser.id) return -1;

                return user.full_name.toLowerCase() > b.full_name.toLowerCase() ? 1 : -1;
              }, participants)}
              header={conversation.is_admin && (
                <ImageItem
                  className="NewConversationModal__Item"
                  icon="group_add"
                  name={t('chat:conversation_form_add_colleagues')}
                  onClick={this.handleAddParticipants}
                />
              )}
              renderRow={Participant}
              rowProps={{
                loggedUser,
                conversation,
                onOpenProfile,
                onAddAdmin,
                onRemoveAdmin,
                onRemove: this.handleRemove,
              }}
            />
            <br />
            {onLeave && (
              <Confirm
                title={t('chat:conversation_form_confirm_leave_group')}
                description={conversation.is_admin && conversation.admin_ids.length === 1
                  ? t('chat:conversation_form_confirm_leave_group_description_admin')
                  : t('chat:conversation_form_confirm_leave_group_description')}
                onConfirm={this.handleLeave}
              >
                <Button type="white" size="fill" icon="logout"><Trans i18nKey="chat:conversation_form_leave_group" /></Button>
              </Confirm>
            )}
            {conversation.creator && (
              <>
                <br />
                <div className="NewConversationModal__Item NewConversationModal__Item--standalone">
                  <small>
                    <Trans
                      i18nKey="chat:conversation_form_created_at"
                      values={{
                        creator: conversation.creator.full_name,
                        createdAt: moment(conversation.created_at).format('DD-MM-YYYY HH:mm'),
                      }}
                    />
                  </small>
                </div>
              </>
            )}
          </>
        )}
        onBack={!onlyParticipants && this.handleBack}
        footer={isAddingParticipants && (
          <Button
            type="primary"
            onClick={this.handleSaveParticipants}
            isLoading={this.state.posting}
          >
            <Trans i18nKey="chat:conversation_form_add_participant" />
          </Button>
        )}
      >
        {children}
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  fetchUsers: require('../../../network/actions/fetch-users').default,
  removeParticipant: require('../../actions/remove-participant').default,
  addParticipants: require('../../actions/add-participants').default,
};

export default withTranslation()(connect(undefined, mapDispatchToProps)(ConversationForm));
