import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { ImageItemUser } from '@common/components/image-item/user';
import Tabs from '@common/components/tabs';
import AsyncList from '@common/components/list/async';

import type { MessageEmojiReaction } from '@common/types/objects';
import type { GetMessageEmojiReactionUsersActionFilter } from '../../../modules/chat/actions';
import type { AsyncListFetchHandler } from '@common/components/list/types';

export type EmojiReactionsUserListProps = {
  show: boolean;
  onSetVisibility: (isVisible: boolean) => void;
  messageId: string;
  conversationId: string;
  emojiReactions: MessageEmojiReaction[];
  // @ts-expect-error
  onFetch: AsyncListFetchHandler<GetMessageEmojiReactionUsersActionFilter>;
  children: JSX.Element;
};

export const EmojiReactionsUserList = ({
  show,
  onSetVisibility,
  messageId,
  conversationId,
  emojiReactions = [],
  onFetch,
  children,
}: EmojiReactionsUserListProps) => {
  const { t } = useTranslation();
  const [modalWrapper, setModalWrapper] = useState<HTMLDivElement>();

  const handleOnShow = () => {
    onSetVisibility(true);

    if (document) {
      setModalWrapper(document.getElementsByClassName('EmojiReactionsUserListModal')[0] as HTMLDivElement);
    }
  };

  return (
    <Modal
      list
      show={show}
      onShow={handleOnShow}
      onClose={() => onSetVisibility(false)}
      title={t('chat:reactions')}
      className="EmojiReactionsUserListModal"
      content={(
        <Tabs variant="full_width" containerClassName="EmojiReactionsUserList__Tabs">
          {emojiReactions.map((emoji) => (
            <Tabs.Item
              key={emoji.short_name}
              title={(
                <>
                  <span>{emoji.character}</span>
                  <span>{emoji.count}</span>
                </>
              )}
            >
              <AsyncList
                containerClassName="EmojiReactionsUserList__AsyncList"
                data={{
                  cache: {
                    nextCursor: emoji.usersNextCursor,
                  },
                  useCursor: true,
                  filter: {
                    conversationId,
                    messageId,
                    reactionShortName: emoji.short_name,
                  },
                  onFetch,
                }}
                items={emoji?.users}
                renderRow={ImageItemUser}
                disableInitialFetch={(emoji?.users?.length || 0) > 0}
                scrollContainer={modalWrapper}
              />
            </Tabs.Item>
          ))}
        </Tabs>
      )}
    >
      {children}
    </Modal>
  );
};
