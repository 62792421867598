import React, { useMemo } from 'react';
import Linkify from 'react-linkify';
import { Trans } from 'react-i18next';
import moment from 'moment';
import * as R from 'ramda';
import GraphemeSplitter from 'grapheme-splitter';
import { useThunkDispatch } from '@hooks/redux';
import { EmojiReactions } from '@common/components/emoji-reactions';
import { EmojiReactionPicker } from '@common/components/emoji-reaction-picker';
import { EmojiReactionsUserList } from '@common/components/emoji-reactions-user-list';
import * as AnalyticsService from '../../../../common/services/analytics';
import Attachment from '../../../../common/components/attachment';
import Icon from '../../../../common/components/icon';
import Dropdown from '../../../../common/components/dropdown';
import Confirm from '../../../../common/components/confirm-button';
import { getMessageEmojiReactionUsersAction } from '../../actions';
import { EFileTypes } from '../../../../common/definitions';

const { EEventNames } = AnalyticsService;

const splitter = new GraphemeSplitter();

let regex;
try {
  // eslint-disable-next-line prefer-regex-literals
  regex = new RegExp('\\p{Extended_Pictographic}', 'u'); // eslint-disable-line no-invalid-regexp
} catch (err) {
  // Regex not supported
}
const getSize = (size) => {
  switch (size) {
    case 1: return 36;
    case 2: return 32;
    case 3: return 24;
    default: return 14;
  }
};

const getFontSize = (text) => {
  const splitted = splitter.splitGraphemes(text); // Some icons contain of multiple unicode characters so we have to use a lib for this
  const shouldResize = regex && splitted && splitted.length <= 3 && splitted.every((c) => regex.test(c)); // If length is 3 or below and they are all emojis

  return shouldResize ? getSize(splitted.length) : null;
};

const PrivateMessageComponent = ({
  item,
  loggedUser,
  conversation,
  allowedEmojis,
  index,
  isGroup,
  onRemove,
  onReply,
  onSetEmojiReaction,
}) => {
  const thunkDispatch = useThunkDispatch();
  const [showSettings, setShowSettings] = React.useState(false);
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [isEmojisModalOpen, setIsEmojiModalOpen] = React.useState(false);
  const [canDelete, setCanDelete] = React.useState(false);

  const timestamp = moment(item.created_at).format('HH:mm');
  const showName = isGroup && index === 0 && item.user?.id !== loggedUser.id;

  let className = 'PrivateMessage';
  className += item.user?.id === loggedUser.id.toString()
    ? ' PrivateMessage--state-sent'
    : ' PrivateMessage--state-received';

  const shouldShowPreview = (attachment) => [EFileTypes.IMAGE, EFileTypes.GIF, EFileTypes.VIDEO].includes(attachment.file_type);

  // Only calculate font size once
  const {
    textFontSize,
    textLineHeight,
    replyFontSize,
    replyLineHeight,
  } = useMemo(() => {
    const textSize = getFontSize(item.text) || undefined;
    const replySize = (item.parent_message?.text && getFontSize(item.parent_message?.text)) || undefined;

    return {
      textFontSize: textSize && `${textSize}px`,
      textLineHeight: textSize && `${textSize * 1.2}px`,
      replyFontSize: replySize && `${replySize}px`,
      replyLineHeight: replySize && `${replySize * 1.2}px`,
    };
  }, [item.text, item.parent_message]);

  // 2 hours
  const timeForSettings = 60 * 60 * 2;

  const handleMouseEnter = () => {
    setShowSettings(true);

    // Limit to 2 hours after posting
    if ((new Date() - new Date(item.created_at)) / 1000 < timeForSettings) setCanDelete(true);
  };

  const handleAddNewReaction = (emoji) => {
    // eslint-disable-next-line camelcase
    const isAlreadySelected = item.reactions.find(({ short_name, is_selected_by_user }) => (
      // eslint-disable-next-line camelcase
      short_name === emoji.short_name && is_selected_by_user
    ));

    onSetEmojiReaction(item.id, emoji, !isAlreadySelected);
  };

  if (item.deleted_at) {
    return (
      <div className={className}>
        <div className="PrivateMessage__Container">
          <div className="PrivateMessage__Inner">
            {showName && <b>{item.user?.full_name}</b>}
            <span className="Linkify">
              <span className="PrivateMessage__Deleted">
                <Icon type="not_interested" />
                <Trans i18nKey="chat:message_removed" />
              </span>
              <small className="PrivateMessage__timestamp PrivateMessage__timestamp--hidden">{timestamp}</small>
            </span>
            <small className="PrivateMessage__timestamp">{timestamp}</small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={className} onMouseEnter={handleMouseEnter} onMouseLeave={() => setShowSettings(false)}>
      {(item.text) && (
        <div className="PrivateMessage__Container">
          <div className="PrivateMessage__Inner">
            {(showSettings || isDropdownOpen || isModalOpen) && (
              <div className="PrivateMessage__OptionsOverlay">
                <EmojiReactionPicker
                  allowedEmojis={allowedEmojis}
                  onSelect={handleAddNewReaction}
                />

                <Dropdown
                  alignRight
                  className="PrivateMessage__OptionsDropdown"
                  onOpen={() => setDropdownOpen(true)}
                  onClose={() => setDropdownOpen(false)}
                  trigger={(
                    <div className="PrivateMessage__Options">
                      <Icon type="more_vert" />
                    </div>
                  )}
                >
                  <Dropdown.Item onClick={() => onReply(item.id)}><Trans i18nKey="chat:reply" /></Dropdown.Item>
                  {!R.isEmpty(item.reactions) && (
                    <EmojiReactionsUserList
                      show={isEmojisModalOpen}
                      onSetVisibility={setIsEmojiModalOpen}
                      messageId={item.id}
                      conversationId={conversation.id}
                      emojiReactions={item.reactions}
                      onFetch={(...args) => thunkDispatch(getMessageEmojiReactionUsersAction(...args))}
                    >
                      <Dropdown.Item>
                        <Trans i18nKey="chat:reactions" />
                      </Dropdown.Item>
                    </EmojiReactionsUserList>
                  )}
                  {canDelete && item.user?.id === loggedUser.id && (
                    <Confirm
                      title={<Trans i18nKey="chat:confirm_remove_message" />}
                      onEnter={() => setModalOpen(true)}
                      onExit={() => {
                        setModalOpen(false);
                        setShowSettings(false);
                      }}
                      onConfirm={() => {
                        onRemove(item.id);
                      }}
                    >
                      <Dropdown.Item danger><Trans i18nKey="chat:remove_message" /></Dropdown.Item>
                    </Confirm>
                  )}
                </Dropdown>
              </div>
            )}
            {showName && <b>{item.user?.full_name}</b>}
            {!!item.parent_message && (
              <div className="PrivateMessage__Reply">
                <div className="PrivateMessage__Reply__Border" />
                <div className="PrivateMessage__Reply__Inner">
                  <b>
                    {item.parent_message.user.id !== loggedUser.id
                      ? item.parent_message.user.full_name
                      : <Trans i18nKey="chat:message_user_you" />}
                  </b>
                  {(item.parent_message.deleted_at && (
                    <div className="PrivateMessage__Deleted"><Trans i18nKey="chat:message_removed" /></div>
                  )) || (item.parent_message.text && (
                    <Linkify
                      properties={{
                        target: '_blank',
                        rel: 'noopener',
                        className: 'linkified',
                      }}
                    >
                      <span style={{ fontSize: replyFontSize, lineHeight: replyLineHeight }}>
                        {item.parent_message.text}
                      </span>
                    </Linkify>
                  ))}
                </div>
              </div>
            )}
            {item.text && (
              <Linkify
                properties={{
                  target: '_blank',
                  rel: 'noopener',
                  className: 'linkified',
                }}
              >
                <span style={{ fontSize: textFontSize, lineHeight: textLineHeight }}>{item.text}</span>
                <small className="PrivateMessage__timestamp PrivateMessage__timestamp--hidden">{timestamp}</small>
              </Linkify>
            )}
            <small className="PrivateMessage__timestamp">{timestamp}</small>
          </div>

          <EmojiReactions
            className="PrivateMessage__EmojiReactions"
            reactions={item.reactions}
            onSetReaction={(...args) => onSetEmojiReaction(item.id, ...args)}
          />
        </div>
      )}

      {
        R.reject(shouldShowPreview, item.attachments).map((attachment) => (
          <div className="PrivateMessage__Container" key={attachment.id}>
            <div className="PrivateMessage__Inner">
              <Attachment
                showFileSize
                key={attachment.id}
                item={attachment}
                onOpen={() => AnalyticsService.track(EEventNames.OPENED_FILE_IN_CHAT, {
                  conversationId: conversation.id,
                  type: AnalyticsService.capitalise(attachment.file_type),
                  fileExtension: AnalyticsService.getExtension(attachment.path),
                })}
              />
              <small className="PrivateMessage__timestamp">{timestamp}</small>
            </div>
          </div>
        ))
      }

      {
        R.filter(shouldShowPreview, item.attachments).map((attachment, subIndex) => (
          <>
            <Attachment
              key={attachment.id}
              item={attachment}
              maxHeight={300}
              title={(showName && subIndex === 0 && !item.text && item.user?.full_name) || undefined}
              label={timestamp}
              onOpen={() => AnalyticsService.track(EEventNames.OPENED_FILE_IN_CHAT, {
                conversationId: conversation.id,
                type: AnalyticsService.capitalise(attachment.file_type),
                fileExtension: AnalyticsService.getExtension(attachment.path),
              })}
              includeLoader
            />
            {(showSettings || isDropdownOpen || isModalOpen) && (
              <Dropdown
                alignRight
                onOpen={() => setDropdownOpen(true)}
                onClose={() => setDropdownOpen(false)}
                trigger={(
                  <div className="PrivateMessage__Options">
                    <Icon type="more_vert" />
                  </div>
                )}
              >
                {canDelete && item.user?.id === loggedUser.id && (
                  <Confirm
                    title={<Trans i18nKey="chat:confirm_remove_message" />}
                    onEnter={() => setModalOpen(true)}
                    onExit={() => {
                      setModalOpen(false);
                      setShowSettings(false);
                    }}
                    onConfirm={() => {
                      onRemove(item.id);
                    }}
                  >
                    <Dropdown.Item danger><Trans i18nKey="chat:remove_message" /></Dropdown.Item>
                  </Confirm>
                )}
              </Dropdown>
            )}
            <div className="PrivateMessage__AttachmentDivider" />
          </>
        ))
      }
    </div>
  );
};

export default PrivateMessageComponent;
