import React from 'react';

import { combineClassNames } from '@utils/combineClassNames';

import type { MessageEmojiReaction } from '@common/types/objects';

type EmojiReactionProps = {
  reaction: MessageEmojiReaction;
  onClick: (emoji: MessageEmojiReaction, isSelected: boolean) => void;
};

export const EmojiReactionButton = ({ reaction, onClick }: EmojiReactionProps) => {
  const fullClassName = combineClassNames('EmojiReactionButton', {
    'EmojiReactionButton--selected': reaction.is_selected_by_user,
  });

  return (
    <button
      className={fullClassName}
      onClick={() => onClick(reaction, !reaction.is_selected_by_user)}
    >
      <span className="EmojiReactionButton__Emoji">{reaction.character}</span>
      <span className="EmojiReactionButton__Count">{reaction.count}</span>
    </button>
  );
};

type EmojiReactionsProps = {
  className?: string;
  reactions: MessageEmojiReaction[];
  onSetReaction: (emoji: MessageEmojiReaction, isSelected: boolean) => void;
};

export const EmojiReactions = ({ className, reactions = [], onSetReaction }: EmojiReactionsProps) => (
  <div className={combineClassNames('EmojiReactions', className)}>
    {reactions.map((reaction) => (
      <EmojiReactionButton
        key={reaction.short_name}
        reaction={reaction}
        onClick={onSetReaction}
      />
    ))}
  </div>
);
