import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { TrackComponent, EEventNames } from '../../../../../client/analytics';
import * as Alert from '../../../../common/services/alert';
import * as AnalyticsService from '../../../../common/services/analytics';
import { Button } from '../../../../common/components/button';
import ButtonGroup from '@common/components/button-group';
import Container from '../../../../common/components/container';
import AsyncList from '../../../../common/components/list/async';
import SearchBar from '../../../../common/components/search-bar';
import Spinner from '../../../../common/components/spinner';
import Placeholder from '../../../../common/components/placeholder';
import CurrentConversation from '../../components/conversation';
import NewConversationForm from '../../forms/new-conversation';
import ConversationItem from '../../components/conversation-item';
import { getAllowedEmojisAction } from '../../../core/actions/allowed-emojis';
import * as conversationsSelector from '../../selectors/conversations';
import * as userSelector from '../../../core/selectors/logged-user';
import * as networkSelector from '../../../network/selectors/network';
import type { User } from '../../../../common/types/objects';
import type { StoreState } from '@common/types/store';

// Map conversation type to meta data for Mixpanel
const conversationTypeToMeta = {
  private: 'Private',
  group: 'Group',
};

require('../../styles.scss');

const mapStateToProps = (state: StoreState) => ({
  ui: state.ui.chat,
  initialised: state.chat.conversations.initialised,
  conversations: conversationsSelector.list(state),
  archive: conversationsSelector.archive(state),
  conversation: conversationsSelector.current(state),
  loggedUser: userSelector.selected(state),
  network: networkSelector.selected(state),
});

const mapDispatchToProps = {
  fetchConversations: require('../../actions/fetch-conversations').default,
  receiveConversations: require('../../actions/fetch-conversations').receiveConversations,
  openConversation: require('../../actions/open-conversation').default,
  postConversation: require('../../actions/post-conversation').default,
  filterConversations: require('../../actions/filter-conversations').default,
  archiveConversation: require('../../actions/archive-conversation').default,
  unarchiveConversation: require('../../actions').unarchiveConversation,
  leaveConversation: require('../../actions/leave-conversation').default,
  toggleNotifications: require('../../actions/toggle-notifications').default,
  addGroupAdmin: require('../../actions').addGroupAdmin,
  removeGroupAdmin: require('../../actions').removeGroupAdmin,
  removeConversation: require('../../actions').removeConversation,
  getAllowedEmojis: getAllowedEmojisAction,
};

const reduxConnector = connect(mapStateToProps, mapDispatchToProps);

type ReduxConnectedProps = ConnectedProps<typeof reduxConnector>;

type Props = ReduxConnectedProps & WithTranslation & RouteComponentProps<{ filter?: string }>;

type State = {
  search?: string | null;
  isNewConversationModalVisible: boolean;
  loadingConversations: boolean;
};

class Conversations extends React.Component<Props, State> {
  static props: Props;

  static pageTitle = 'Chat';

  handleSearch: (query?: string) => void;

  handleOpenProfile: (userId: string) => void;

  constructor(props: Props) {
    super(props);

    this.state = {
      search: null,
      isNewConversationModalVisible: false,
      loadingConversations: false
    };

    this.handleSearch = (search) => this.setState({ search });
    this.handleOpenProfile = (userId) => props.history.push(
      props.match.path.includes('/admin')
        ? `/admin/users/${userId}`
        : `/networks/${props.network.id}/users/${userId}`,
    );
    this.handleNewConversation = this.handleNewConversation.bind(this);
    this.handleNewGroupConversation = this.handleNewGroupConversation.bind(this);
    this.handleArchive = this.handleArchive.bind(this);
    this.handleUnarchive = this.handleUnarchive.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    this.handleToggleNotifications = this.handleToggleNotifications.bind(this);
    this.setLoadingConversations = this.setLoadingConversations.bind(this);
  }

  setLoadingConversations(value: boolean) {
    this.setState({ loadingConversations: value });
  }

  componentDidMount() {
    const { ui, openConversation, getAllowedEmojis } = this.props;

    getAllowedEmojis();
    if (ui.selectedConversationId) openConversation(ui.selectedConversationId);
  }

  async handleNewConversation(user: User): Promise<void> {
    await this.props.postConversation({
      type: 'private',
      participant_ids: [user.id],
    });

    this.setState({ isNewConversationModalVisible: false });
  }

  async handleNewGroupConversation(payload: Object): Promise<void> {
    await this.props.postConversation({
      ...payload,
      type: 'group',
    });

    this.setState({ isNewConversationModalVisible: false });
  }

  async handleArchive(id: string): Promise<boolean> {
    const { archiveConversation, t } = this.props;

    try {
      await archiveConversation(id);

      Alert.success(t('chat:conversation_alert_archived'));

      return true;
    } catch (err) {
      Alert.error(t('chat:conversation_alert_error_archiving'));

      return false;
    }
  }

  async handleUnarchive(id: string): Promise<boolean> {
    const { unarchiveConversation, t } = this.props;

    try {
      await unarchiveConversation(id);

      Alert.success(t('chat:conversation_alert_unarchived'));

      return true;
    } catch (err) {
      Alert.error(t('chat:conversation_alert_error_unarchiving'));

      return false;
    }
  }

  async handleLeave(id: string): Promise<boolean> {
    const { leaveConversation, t } = this.props;

    try {
      await leaveConversation(id);

      Alert.success(t('chat:conversation_alert_left'));

      return true;
    } catch (err) {
      Alert.error(t('chat:conversation_alert_error_leaving'));

      return false;
    }
  }

  async handleToggleNotifications(id: string, shouldMute: boolean): Promise<boolean> {
    const { toggleNotifications, t } = this.props;

    try {
      await toggleNotifications(id, shouldMute);

      Alert.success(t(shouldMute ? 'chat:conversation_alert_muted' : 'chat:conversation_alert_unmuted'));

      return true;
    } catch (err) {
      Alert.error(t('chat:conversation_alert_error_leaving'));

      return false;
    }
  }

  render() {
    const { search, isNewConversationModalVisible } = this.state;
    const {
      history,
      match,
      network,
      loggedUser,
      conversations,
      archive,
      conversation,
      ui,
      openConversation,
      t,
      fetchConversations
    } = this.props;
    const { params } = match;

    // console.log("debug conversation", conversation);
    // console.log("debug conversations", conversations);

    return (
      <Container name="Chat">
        <Container.Content horizontal>
          <Container.SideBar size="large">
            <div className="MyConversations">
              <div className="wrapper__sidebar__search Form">
                {/* @ts-expect-error */}
                <SearchBar defaultValue={ui.search.query} onSearch={this.handleSearch} />

                <NewConversationForm
                  show={isNewConversationModalVisible}
                  onClose={() => this.setState({ isNewConversationModalVisible: false })}
                  onShow={() => this.setState({ isNewConversationModalVisible: true })}
                  {...{ network, loggedUser }}
                  onNewConversation={this.handleNewConversation}
                  onNewGroupConversation={this.handleNewGroupConversation}
                >
                  <Button type="primary" className="pull-right" iconRight="add">
                    <Trans i18nKey="chat:conversations_create_new_chat" />
                  </Button>
                </NewConversationForm>
              </div>

              <div className="balloon MyConversations__container">
                <div className="balloon__header">
                  <ButtonGroup
                    size="large"
                    active={params.filter || ''}
                    onChange={(filter) => (
                      history.replace(filter ? `${match.path}/${filter}` : match.path.replace('/:filter', ''))
                    )}
                  >
                    <Button value=""><Trans i18nKey="chat:conversations_my_messages" /></Button>
                    <Button value="archived"><Trans i18nKey="chat:conversations_archive_messages" /></Button>
                  </ButtonGroup>
                </div>

                <AsyncList
                  containerClassName="MyConversations__list"
                  // @ts-expect-error
                  items={params.filter === 'archived' ? archive : conversations}
                  data={{
                    useCursor: true,
                    // @ts-expect-error
                    cache: params.filter === 'archived' ? archive : conversations,
                    onFetch: async (...args: any[]) => {
                      this.setLoadingConversations(true);
                      const result = await fetchConversations(...args);
                      this.setLoadingConversations(false);
                      return result;
                    },
                    onPostFetch: ({ data }, strategy, filter) => {
                      if (!conversation && data.length > 0) openConversation(data[0].id);
                      // @ts-expect-error
                      if (!strategy && !filter.search) {
                        AnalyticsService.track(
                          // @ts-expect-error
                          filter.archived
                            ? EEventNames.VIEWED_ARCHIVED_CHAT_CONVERSATIONS
                            : EEventNames.VIEWED_CHAT_CONVERSATIONS,
                        );
                      }
                    },
                    filter: {
                      archived: params.filter,
                      search,
                    },
                  }}
                  renderRow={ConversationItem}
                  rowProps={{
                    loggedUser,
                    onClick: this.props.openConversation,
                    onOpenProfile: this.handleOpenProfile,
                    onToggleNotifications: this.handleToggleNotifications,
                    onArchive: this.handleArchive,
                    onUnarchive: this.handleUnarchive,
                    onLeave: this.handleLeave,
                    onAddGroupAdmin: this.props.addGroupAdmin,
                    onRemoveGroupAdmin: this.props.removeGroupAdmin,
                    onRemoveConversation: this.props.removeConversation,
                  }}
                  placeholder={params.filter === 'archived' && (
                    <Placeholder title={t('chat:archive_placeholder_title')} />
                  )}
                  ShowMoreComponent={({ onShowMore, isFetching }) => (
                    <div className="MyConversations__List__ShowMore" onClick={onShowMore}>
                      {isFetching
                        ? <Spinner centered />
                        : <Trans i18nKey="chat:conversations_load_more" />}
                    </div>
                  )}
                />
              </div>
            </div>
          </Container.SideBar>
          <Container.Column>
            { this.renderContent() }
          </Container.Column>
        </Container.Content>
      </Container>
    );
  }

  renderContent() {
    const { loadingConversations } = this.state;
    const {
      conversation, conversations, loggedUser, addGroupAdmin, removeGroupAdmin,
      t
    } = this.props;


    if (conversation) {
      return (
        <TrackComponent
          key={conversation.id}
          eventName={EEventNames.VIEWED_CHAT_CONVERSATION}
          // @ts-expect-error
          properties={{ type: conversationTypeToMeta[conversation.conversation_type] }}
        >
          <CurrentConversation
            // @ts-expect-error
            conversation={conversation}
            loggedUser={loggedUser}
            onOpenProfile={this.handleOpenProfile}
            onToggleNotifications={this.handleToggleNotifications}
            onArchive={this.handleArchive}
            onLeave={this.handleLeave}
            onAddGroupAdmin={addGroupAdmin}
            onRemoveGroupAdmin={removeGroupAdmin}
          />
        </TrackComponent>
      );
    }

    if (loadingConversations) {
      return <Spinner centered size="large" />;
    }

    if (conversations.length === 0) {
      return (
        <Placeholder
          image="/static/images/placeholders/chat.svg"
          title={t('chat:conversations_placeholder_title')}
          description={t('chat:conversations_placeholder_description')}
          action={(
            <Button
              type="primary"
              className="pull-right"
              iconRight="add"
              onClick={() => this.setState({ isNewConversationModalVisible: true })}
            >
              <Trans i18nKey="chat:conversations_placeholder_create_new_chat" />
            </Button>
          )}
        />
      );
    }
  }
}

export default withTranslation()(reduxConnector(Conversations));
