import * as React from 'react';
import { createDateStamp } from '../../../../common/utils/create-timestamp';

const DaySectionHeader = ({ date }) => {
  const text = createDateStamp(date);

  return <div className="Conversation__DayHeader"><span>{text}</span></div>;
};

export default DaySectionHeader;
