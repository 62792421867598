import Api from '../../../common/services/api';

export const CHAT_ARCHIVE_CONVERSATION = 'chat/ARCHIVE_CONVERSATION';

export default (conversationId) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  await Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/archive`);

  return dispatch({
    type: CHAT_ARCHIVE_CONVERSATION,
    conversationId,
    sync: true,
  });
};
