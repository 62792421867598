import * as React from 'react';
import { connect } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import useUserStatusRemoteUpdateListener from '@common/hooks/use-user-status-remote-update-listener';
import { CHAT_SET_USERS_STATUS } from '@modules/chat/actions';
import { UserStatusBadge } from '@common/components/profile-image';
import Icon from '../../../../common/components/icon';
import ConversationImage from '../conversation-image';
import { createTimeStamp } from '../../../../common/utils/create-timestamp';
import ConversationActions from '../conversation-actions';
import { ConversationArchivedActions } from '../conversation-archived-actions';
import * as conversationsSelector from '../../selectors/conversations';
import { EMessageTypes, EConversationTypes } from '../../definitions';

const ConversationItem = (props) => {
  // console.log("debug ConversationItem props", props);
  const {
    item,
    loggedUser,
    onClick,
    onOpenProfile,
    onToggleNotifications,
    onArchive,
    onUnarchive,
    onLeave,
    onAddGroupAdmin,
    onRemoveGroupAdmin,
    onRemoveConversation,
    status
  } = props;
  const [showOptions, setShowOptions] = React.useState(false);
  const { t } = useTranslation();

  let className = 'ConversationItem fs-exclude ImageItem ImageItem--large';
  if (item.is_active) className += ' ConversationItem--state-active';

  let content;

  const attachmentPrefix = (item.last_message && item.last_message.attachments[0]) ?
    `(${
      t('chat:conversation_item_attachment_prefix', {
        context: item.last_message.attachments[0].file_type
      })
    }) `
    : '';

  if (!item.last_message) {
    content = <small><Trans i18nKey="chat:conversation_item_no_messages" /></small>;
  } else if (item.last_message.deleted_at) {
    content = <small><Trans i18nKey="chat:message_removed" /></small>;
  } else {
    const text = (
      <>
        { attachmentPrefix }
        { item.last_message.text }
      </>
    );

    // Prefix name of creator of message when conversation is a group message and it's a message
    /* eslint-disable react/jsx-indent */
    content = (
      item.conversation_type === EConversationTypes.GROUP &&
      item.last_message.user &&
      item.last_message.type === EMessageTypes.MESSAGE
    ) ? (
        <>
          <span className="ConversationItem__LastMessage__Creator">
            {
              `${
                item.last_message.user.id === loggedUser.id ?
                  t('chat:conversation_participant_yourself') :
                  item.last_message.user.first_name
              }: `
            }
          </span>
          {text}
        </>
      ) :
      text;
  }

  const handleClick = (e) => {
    if (e.target.classList.contains('ConversationActions') || e.target.classList.contains('Button') || e.target.classList.contains('dropdown-item')) return;

    onClick(item.id);
  };

  /* eslint-disable react/destructuring-assignment */
  // if this is not a 1-1 then conversation id is going to be undefined and we
  // don't listen for user status updates
  const id = props?.item?.participant?.id;
  useUserStatusRemoteUpdateListener(id || '', (data, dispatch, userId) => {
    dispatch({
      type: CHAT_SET_USERS_STATUS,
      statuses: {
        [userId]: (
          data?.status ?
            {
              text: data.status.text,
              emoji: data.status.emoji,
              expires_at: data.status.expires_at
            } :
            null
        )
      }
    });
  });

  return (
    <div
      role="button"
      className={className}
      onClick={handleClick}
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => setShowOptions(false)}
    >
      {
        status ?
          <UserStatusBadge
            formattedStatus={status}
            profileImageSize={40}
          /> :
          null
      }
      <ConversationImage
        loggedUser={loggedUser}
        conversation={item}
        className={status && 'hasStatus'}
      />
      <div className="ImageItem__info">
        <h5 className="ImageItem__info__name">
          <div>
            {item.name}
            {item.is_muted && <Icon type="notifications_off" />}
          </div>
          <small className="pull-right">
            {createTimeStamp(item.last_message ? item.last_message.created_at : item.created_at)}
          </small>
        </h5>
        <div className="ConversationItem__Content">
          <div className="ConversationItem__LastMessage">
            {/* $FlowFixMe */}
            {item.unread && <span className="ConversationItem__Unread" />}
            {content || ''}
          </div>
          {item.is_archived ? (
            <div className="ConversationItem__Archived">
              <small className="ConversationItem__ArchivedLabel">
                <Trans i18nKey="chat:conversation_archived" />
              </small>

              <ConversationArchivedActions
                conversation={item}
                onUnarchive={onUnarchive}
                onRemoveConversation={onRemoveConversation}
              />
            </div>
          ) : (showOptions && (
            <ConversationActions
              conversation={item}
              loggedUser={loggedUser}
              onOpenProfile={onOpenProfile}
              onToggleNotifications={onToggleNotifications}
              onArchive={onArchive}
              onLeave={onLeave}
              onAddGroupAdmin={onAddGroupAdmin}
              onRemoveGroupAdmin={onRemoveGroupAdmin}
              onRemoveConversation={onRemoveConversation}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = () => {
  const itemSelector = conversationsSelector.item();
  // $FlowFixMe
  return (state, props) => {
    const item = itemSelector(state, props.item.id);
    const partId = item.participant?.id;
    return {
      item,
      status: partId ?
        conversationsSelector.getFormattedUserStatus(state, partId) :
        null
    };
  };
};

export default connect(mapStateToProps)(ConversationItem);
