import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router';
import * as R from 'ramda';
import moment from 'moment';
import { withTranslation, Trans } from 'react-i18next';
import ProfileImage from '@common/components/profile-image';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import PostMessageForm from '../../forms/post-message';
import ConversationForm from '../../forms/conversation';
import ConversationImage from '../conversation-image';
import ConversationActions from '../conversation-actions';
import {
  addMessageEmojiReactionAction, removeMessageEmojiReactionAction
} from '../../actions';
import { AlertService } from '@services/alert';
import DaySectionHeader from './day-section-header';
import MessageItem from '../message';
import ActivityItem from '../activity';
import * as conversationSelector from '../../selectors/conversation';
import { listAllowedEmojis } from '../../../core/selectors/allowed-emojis';
import { EConversationTypes, EMessageTypes } from '../../definitions';
import type { LoggedUser, History, AllowedEmoji } from '@common/types/objects';
import type { FullConversation } from '../../types/objects';
import { StoreState } from '@common/types/store';
import UserStatusPreview from '@common/components/user-status/user-status-preview';

// @ts-expect-error
const formatDate = (message) => moment(message.created_at).format('YYYY-MM-DD');

const mapStateToProps = (state: StoreState, props: Record<string, any>) => {
  const isGroupConversation = !props.conversation.participant;
  return {
    messages: conversationSelector.messages(state),
    participantStatus: (
      isGroupConversation ?
        null :
        conversationSelector.getUserStatus(
          state,
          // @ts-expect-error
          props.conversation.participant.id
        )
    ),
    groupStatuses: (
      isGroupConversation ?
        conversationSelector.getGroupConversationUsersStatus(state) :
        null
    ),
    pagination: conversationSelector.pagination(state),
    allowedEmojis: listAllowedEmojis(state),
  };
};

const mapDispatchToProps = {
  addMessageEmojiReaction: addMessageEmojiReactionAction,
  removeMessageEmojiReaction: removeMessageEmojiReactionAction,
  change: require('redux-form').change,
  fetchMessages: require('../../actions/fetch-messages').default,
  seen: require('../../actions/seen').default,
  clearNotifications: require('../../actions/clear-notifications').default,
  removeMessage: require('../../actions').removeMessage,
  removeConversation: require('../../actions').removeConversation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type OwnProps = {
  history: History,
  loggedUser: LoggedUser,
  conversation: FullConversation,
  onOpenProfile: (userId: string) => void,
  onToggleNotifications: (id: string, shouldMute: boolean) => Promise<void>,
  onArchive: (id: string) => Promise<void>,
  onLeave: (id: string) => Promise<void>,
  onAddGroupAdmin: (conversationId: string, userId: string) => Promise<void>,
  onRemoveGroupAdmin: (conversationId: string, userId: string) => Promise<void>,
  removeConversation: (conversationId: string) => Promise<void>,
  t: (key: string) => string,
};

type Props = OwnProps & ConnectedProps<typeof connector>;

type State = {
  isFetching: boolean;
  isFetchingMore: boolean;
};

const BOTTOM_AREA_MARGIN = 50;

class ConversationComponent extends React.Component<Props, State> {
  static props: Props;
  list?: HTMLDivElement;
  content?: HTMLDivElement;
  setListReference: (ref?: HTMLDivElement) => HTMLDivElement | undefined;
  setContentReference: (ref?: HTMLDivElement) => HTMLDivElement | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      // @ts-expect-error
      isFetching: props.pagination.loaded === false,
      isFetchingMore: false,
    };

    // @ts-expect-error
    this.setListReference = (ref) => {
      if (!ref) return;

      this.list = ref;

      // Scroll to bottom of chat on mount
      setTimeout(() => (ref.scrollTop = ref.scrollHeight), 0); // eslint-disable-line no-param-reassign
    };
    this.setContentReference = (ref) => (this.content = ref);
    // @ts-expect-error
    this.setInputReference = (ref) => (this.input = ref);
    this.handleScrollToBottom = this.handleScrollToBottom.bind(this);
    this.handleShowMore = this.handleShowMore.bind(this);
  }

  componentDidMount() {
    const { conversation, pagination, seen, clearNotifications } = this.props;

    // @ts-expect-error
    if (pagination.loaded === false) this.loadMessages();

    seen(conversation.id);
    clearNotifications(conversation.id);

    // Clear notifications when you re-focus the page
    window.onfocus = () => clearNotifications(conversation.id);
  }

  getSnapshotBeforeUpdate() {
    return {
      scrollFromBottomOfChat: this.getScrollFromBottom()
    };
  }

  componentDidUpdate(
    prevProps: Props,
    _: State,
    { scrollFromBottomOfChat }: ReturnType<ConversationComponent['getSnapshotBeforeUpdate']>
  ) {
    const { conversation, seen } = this.props;

    if (!conversation.last_message || !prevProps.conversation.last_message) return;

    if (conversation.last_message.id !== prevProps.conversation.last_message.id) {
      // If there is a new message, set it to seen
      seen(conversation.id);

      const scrollIsAtBottomOfChat = scrollFromBottomOfChat <= BOTTOM_AREA_MARGIN;
      if (scrollIsAtBottomOfChat && this.list && this.content) {
        this.handleScrollToBottom();
      }
    }
  }

  componentWillUnmount() {
    window.onfocus = null;
  }

  getScrollFromBottom() {
    if (!this.list) return Infinity;
    return this.list.scrollHeight - this.list.scrollTop - this.list.offsetHeight;
  }

  // @ts-expect-error
  async loadMessages(cursor) {
    this.setState({ isFetching: !cursor, isFetchingMore: !!cursor });

    await this.props.fetchMessages(cursor);

    this.setState({ isFetching: false, isFetchingMore: false }, () => {
      // scroll to bottom only if it is the first load, because otherwise the
      // loadMessages call comes from a "load more" click (and therefore it has a cursor)
      if (!cursor) {
        this.handleScrollToBottom();
      }
    });
  }

  handleScrollToBottom() {
    if (!this.list) return;
    if (this.list.scrollTo) {
      this.list.scrollTo(0, this.list.scrollHeight);
    } else {
      this.list.scrollTop = this.list.scrollHeight;
    }
  }

  async handleShowMore() {
    const { pagination } = this.props;

    const oldHeight = this.content && this.content.offsetHeight || 0;

    // @ts-expect-error
    await this.loadMessages(pagination.next_cursor);

    if (this.list && this.content && oldHeight) {
      this.list.scrollTop = this.list.scrollTop + this.content.offsetHeight - oldHeight;
    }
  }

  handleSettingMessageEmojiReaction = (messageId: string, allowedEmoji: AllowedEmoji, isSelected: boolean) => {
    const { conversation, addMessageEmojiReaction, removeMessageEmojiReaction, t } = this.props;
    try {
      if (isSelected) {
        return addMessageEmojiReaction(conversation.id, messageId, allowedEmoji);
      }

      removeMessageEmojiReaction(conversation.id, messageId, allowedEmoji);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('chat:setting_emoji_reaction_warning'),
          error: t('chat:setting_emoji_reaction_error'),
        });
      }
    }
  };

  render() {
    const { isFetching, isFetchingMore } = this.state;
    const {
      allowedEmojis,
      loggedUser,
      conversation,
      pagination,
      onOpenProfile,
      onToggleNotifications,
      onArchive,
      onLeave,
      onAddGroupAdmin,
      onRemoveGroupAdmin,
      removeConversation,
      removeMessage,
      change,
      participantStatus,
      groupStatuses
    } = this.props;
    // console.log("debug ConversationComponent this.props", this.props);

    const isGroup = conversation.conversation_type === EConversationTypes.GROUP;

    const days = R.pipe(
      R.groupBy(formatDate),
      R.mapObjIndexed((items) => ({
        date: items[0].created_at,
        sections: items.reduce((acc, message, i) => {
          if (i === 0) {
            acc.push({
              user: message.user,
              messages: [message],
            });
          } else {
            const lastUserSection = acc[acc.length - 1];

            if (
              !message.user && lastUserSection.user
              || lastUserSection.user && lastUserSection.user.id !== message.user.id
              || lastUserSection.messages[0].type !== message.type
            ) {
              acc.push({
                user: message.user,
                messages: [],
              });
            }

            acc[acc.length - 1].messages.push(message);
          }

          return acc;
        }, []),
      })),
      R.values,
    // @ts-expect-error
    )(this.props.messages);

    // console.log("debug participantStatus", participantStatus);
    // console.log("debug this.props.messages", this.props.messages);

    const header = (
      <div className={`ImageItem ImageItem--large${conversation.has_left || !isGroup ? ' disabled' : ''}`}>
        <ConversationImage loggedUser={loggedUser} conversation={conversation} />
        <div className="ImageItem__info conversationHead">
          <h5 className="ImageItem__info__name">
            {conversation.name}
            {conversation.is_muted && <Icon type="notifications_off" />}
          </h5>
          {
            participantStatus ?
              <UserStatusPreview status={participantStatus} /> :
              null
          }
        </div>
      </div>
    );

    const listRef = !isFetching ? this.setListReference : undefined;

    return (
      <div className="Conversation">
        <div className="Conversation__Header fs-mask">
          {isGroup && !conversation.has_left ? (
            <ConversationForm
              loggedUser={loggedUser}
              conversation={conversation}
              onOpenProfile={onOpenProfile}
              onLeave={onLeave}
              // @ts-expect-error
              onAddAdmin={(userId) => onAddGroupAdmin(conversation.id, userId)}
              // @ts-expect-error
              onRemoveAdmin={(userId) => onRemoveGroupAdmin(conversation.id, userId)}
            >
              {header}
            </ConversationForm>
          ) : header}

          <ConversationActions
            conversation={conversation}
            loggedUser={loggedUser}
            onOpenProfile={onOpenProfile}
            onToggleNotifications={onToggleNotifications}
            onArchive={onArchive}
            onLeave={onLeave}
            onAddGroupAdmin={onAddGroupAdmin}
            onRemoveGroupAdmin={onRemoveGroupAdmin}
            onRemoveConversation={removeConversation}
          />
        </div>

        {/* @ts-expect-error */}
        <div className="Conversation__Content fs-exclude" ref={listRef}>
          {isFetching && <Spinner centered size="large" />}
          {!isFetching && days.length === 0 && (
            <div className="Conversation__Content__Placeholder">
              <div><Icon type="chat__filled" /></div>
              <Trans i18nKey="chat:conversation_messages_placeholder" />
            </div>
          )}
          {!isFetching && (
            // @ts-expect-error
            <div ref={this.setContentReference}>
              {/* @ts-expect-error */}
              {pagination.next_cursor && (
                <Button
                  isLoading={isFetchingMore}
                  className="Conversation__LoadMore"
                  onClick={this.handleShowMore}
                >
                  <Trans i18nKey="chat:conversation_load_older_messages" />
                </Button>
              )}
              {days.map((day) => (
                <React.Fragment key={day.date}>
                  <DaySectionHeader date={day.date} />

                  {/* @ts-expect-error */}
                  {day.sections.map(({ user, messages }) => (
                    <div key={messages[0]?.id} className="Conversation__UserSection">
                      <div className="Conversation__UserSection__Content">
                        {user && user.id !== loggedUser.id && isGroup && (
                          <div className="Conversation__UserSection__User">
                            <ProfileImage
                              size={36}
                              user={messages[0].user}
                              status={groupStatuses[messages[0].user.id]}
                            />
                          </div>
                        )}
                        <div className="Conversation__UserSection__Messages">
                          {/* @ts-expect-error */}
                          {messages.map((item, index) => {
                            switch (item.type) {
                              case EMessageTypes.MESSAGE:
                                return (
                                  <MessageItem
                                    key={item.id}
                                    index={index}
                                    isGroup={isGroup}
                                    item={item}
                                    loggedUser={loggedUser}
                                    conversation={conversation}
                                    allowedEmojis={allowedEmojis}
                                    onSetEmojiReaction={this.handleSettingMessageEmojiReaction}
                                    // @ts-expect-error
                                    onRemove={(messageId) => removeMessage(conversation.id, messageId)}
                                    // @ts-expect-error
                                    onReply={(messageId) => {
                                      change(`post-message/${conversation.id}`, 'message_id', messageId);
                                      // @ts-expect-error
                                      if (this.input) this.input.focus();
                                    }}
                                  />
                                );
                              case EMessageTypes.ACTIVITY:
                                return (
                                  <ActivityItem
                                    key={item.id}
                                    item={item}
                                    loggedUser={loggedUser}
                                    conversation={conversation}
                                  />
                                );
                              default:
                                return null;
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  ))}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>

        {conversation.has_left ? (
          <div className="PostPrivateMessage PostPrivateMessage--disabled">
            <Trans i18nKey="chat:conversation_has_left_message" />
          </div>
        ) : (
          <PostMessageForm
            // @ts-expect-error
            inputRef={this.setInputReference}
            form={`post-message/${conversation.id}`}
            loggedUser={loggedUser}
            conversationId={conversation.id}
          />
        )}
      </div>
    );
  }
}

export default withTranslation()(
  withRouter(
    // @ts-expect-error
    connect(mapStateToProps, mapDispatchToProps)(ConversationComponent)
  )
);
