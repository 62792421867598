import * as React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import Icon from '../../../../common/components/icon';
import Dropdown from '../../../../common/components/dropdown';
import Confirm from '../../../../common/components/confirm-button';
import ConversationForm from '../../forms/conversation';
import { EConversationTypes } from '../../definitions';

const ConversationActionsComponent = ({
  conversation,
  loggedUser,
  onToggleNotifications,
  onArchive,
  onLeave,
  onRemoveConversation,
  onOpenProfile,
  onAddGroupAdmin,
  onRemoveGroupAdmin,
  t,
}) => (!conversation.has_left ? (
  <Dropdown
    alignRight
    id="card-dropdown"
    toggle={(
      <Icon
        type="more_vert"
        className="ConversationActions"
      />
    )}
  >
    {conversation.conversation_type === EConversationTypes.GROUP && (
      <ConversationForm
        loggedUser={loggedUser}
        conversation={conversation}
        onOpenProfile={onOpenProfile}
        onLeave={onLeave}
        onAddAdmin={(userId) => onAddGroupAdmin(conversation.id, userId)}
        onRemoveAdmin={(userId) => onRemoveGroupAdmin(conversation.id, userId)}
      >
        <Dropdown.Item>
          <Trans i18nKey="chat:conversation_group_information" />
        </Dropdown.Item>
      </ConversationForm>
    )}
    {/* $FlowFixMe */}
    {conversation.conversation_type === EConversationTypes.PRIVATE && conversation.participant && (
      // $FlowFixMe
      <Dropdown.Item onClick={() => onOpenProfile(conversation.participant.id)}>
        <Trans i18nKey="chat:conversation_participant_open_profile" />
      </Dropdown.Item>
    )}
    <Dropdown.Item onClick={() => onToggleNotifications(conversation.id, !conversation.is_muted)}>
      {
        conversation.is_muted ?
          <Trans i18nKey="chat:conversation_enable_notifications" /> :
          <Trans i18nKey="chat:conversation_disable_notifications" />
      }
    </Dropdown.Item>
    <Dropdown.Divider />
    <Confirm
      title={t('chat:conversation_confirm_archive_title')}
      description={t('chat:conversation_confirm_archive_description')}
      onConfirm={() => onArchive(conversation.id)}
    >
      <Dropdown.Item>
        <Trans i18nKey="chat:conversation_archive_conversation" />
      </Dropdown.Item>
    </Confirm>
    {conversation.conversation_type === EConversationTypes.GROUP && (
      <Confirm
        title={t('chat:conversation_form_confirm_leave_group')}
        description={conversation.is_admin && conversation.admin_ids.length === 1
          ? t('chat:conversation_form_confirm_leave_group_description_admin')
          : t('chat:conversation_form_confirm_leave_group_description')}
        onConfirm={() => onLeave(conversation.id)}
      >
        <Dropdown.Item danger>
          <Trans i18nKey="chat:conversation_leave_conversation" />
        </Dropdown.Item>
      </Confirm>
    )}
    <Confirm
      title={t('chat:conversation_remove_confirm_title')}
      onConfirm={() => onRemoveConversation(conversation.id)}
    >
      <Dropdown.Item danger>
        {t('chat:remove_conversation')}
      </Dropdown.Item>
    </Confirm>
  </Dropdown>
) : (
  <Confirm
    title={t('chat:conversation_confirm_archive_title')}
    description={t('chat:conversation_confirm_archive_description')}
    onConfirm={() => onArchive(conversation.id)}
  >
    <Icon type="inventory" className="ConversationActions" />
  </Confirm>
));

export default withTranslation()(ConversationActionsComponent);
