import * as R from 'ramda';
import { ThunkAction } from 'redux-thunk';

import { Api } from '@services/api';

import type { StoreState } from '@common/types/store';
import type { AllowedEmoji } from '@common/types/objects';

type GetAllowedEmojisApiResponse = {
  data: AllowedEmoji[];
};

export type GetAllowedEmojisAction = {
  type: 'allowed-emojis/RECEIVE_ALLOWED_EMOJIS';
  items: AllowedEmoji[];
};

type ActualGetAllowedEmojisAction = ThunkAction<
Promise<GetAllowedEmojisAction | void>,
StoreState,
unknown,
GetAllowedEmojisAction
>;

export const getAllowedEmojisAction = (): ActualGetAllowedEmojisAction => async (dispatch, getState) => {
  const { allowedEmojis: { items: allowedEmojis } } = getState();
  if (!R.isEmpty(allowedEmojis)) return;

  const { data } = await Api.get<GetAllowedEmojisApiResponse>('/v1/reactions');

  return dispatch({
    type: 'allowed-emojis/RECEIVE_ALLOWED_EMOJIS',
    items: data,
  });
};
