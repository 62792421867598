import * as R from 'ramda';
import { createSelector } from 'reselect';

import { formatUserStatus } from '@modules/core/selectors/logged-user';
import memoize from '../../../common/utils/memoize';
import * as searchUtil from '../../../common/utils/search';
import * as conversationsReducer from '../reducers/conversations';

const getChat = (state) => state.chat;

export const getConversationsRelatedUsers = createSelector(
  [getChat],
  (chat) => chat.conversations?.related?.users || null
);

const byLastMessage = R.descend((conversation) => (conversation.last_message
  ? conversation.last_message.created_at
  : conversation.created_at));
const searchQuery = R.curry((search, conversation) => searchUtil.includesInQuery(conversation, search.query, ['name']));

const getConversations = (state) => state.chat.conversations.ids;
const getArchive = (state) => state.chat.conversations.archive;
const getConversation = (state, id) => state.chat.conversations.items[id];
const getActiveState = (state, id) => state.chat.conversations.current === id;
const getSelectedConversation = (state) => state.ui.chat.selectedConversationId;
const getFilter = (state) => state.ui.chat.search;
const getCurrentConversation = (state) => state.chat.conversations.current
  && state.chat.conversations.items[state.chat.conversations.current];
const getCurrentConversationLastMessage = (state) => state.chat.conversations.current
  && state.chat.messages.conversations[state.chat.conversations.current];
const getConversationLastMessages = (state, id) => state.chat.messages.conversations[id];
const getUnread = (state, id) => state.notifications.chat.items[id];
const shouldUpdate = (state) => state.chat.conversations.update;

const listFn = (state, ids) => R.pipe(
  R.map((id) => conversationsReducer.findById(state, id)),
  R.reject((conversation) => R.isNil(conversation.last_message) && conversation.id !== state.ui.chat.selectedConversationId),
  R.filter(searchQuery(state.ui.chat.search)),
  R.sort(byLastMessage),
  R.map(R.pick(['id'])),
)(ids);

export const list = memoize.createSelector(
  [getConversations, getFilter, getSelectedConversation, shouldUpdate],
  listFn,
);

export const archive = memoize.createSelector(
  [getArchive, getFilter, getSelectedConversation, shouldUpdate],
  listFn,
);

const getConversationParticipantMeta = createSelector(
  [
    getConversationsRelatedUsers,
    (state, userId) => userId
  ],
  (users, userId) => {
    return users?.find((user) => user?.id === userId);
  }
);

export const getFormattedUserStatus = createSelector(
  [getConversationParticipantMeta],
  (userMeta) => {
    // console.log("debug userMeta", userMeta);
    return formatUserStatus(userMeta?.membership?.status);
  }
);

export const current = memoize.createSelector(
  [getCurrentConversation, getCurrentConversationLastMessage],
  (state, conversation) => conversation && conversationsReducer.findById(state, conversation.id),
);

export const item = memoize.createItemSelector(
  [getConversation, getConversationLastMessages, getActiveState, getUnread],
  (state, c, lastMessage, isActive) => {
    const conversation = c && conversationsReducer.findById(state, c.id);

    conversation.is_active = isActive;

    return conversation;
  },
);
