import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '@common/components/confirm-button';
import Dropdown from '../../../../common/components/dropdown';
import Icon from '../../../../common/components/icon';

export const ConversationArchivedActions = ({ conversation, onUnarchive, onRemoveConversation }) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      alignRight
      className="ConversationArchivedActions"
      toggle={<Icon className="ConversationArchivedActions__Icon" type="more_vert" />}
    >
      <Dropdown.Item onClick={() => onUnarchive(conversation.id)}>
        {t('chat:conversation_unarchive')}
      </Dropdown.Item>

      <ConfirmButton
        title={t('chat:conversation_remove_confirm_title')}
        onConfirm={() => onRemoveConversation(conversation.id)}
      >
        <Dropdown.Item danger>
          {t('chat:remove_conversation')}
        </Dropdown.Item>
      </ConfirmButton>
    </Dropdown>
  );
};
