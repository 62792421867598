import Api from '../../../common/services/api';
import addMessage from './add-message';

export default (conversationId, values) => async (dispatch, getState) => {
  const { organisation: { selected } } = getState();

  const payloads = [];
  if (values.text) {
    const payload = { text: values.text };
    if (values.message_id) payload.message_id = values.message_id;

    payloads.push(payload);
  }

  const attachments = [];

  // Gif overwrites other attachements
  if (values.gif) {
    attachments.push(values.gif);
  } else {
    values.attachments.forEach((attachment) => attachments.push(attachment));
  }

  // Send all attachments as separate message
  if (attachments.length > 0) {
    payloads.push({ text: '', files: attachments.map((attachment) => attachment.id) });
  }

  payloads.map(async (payload) => {
    const { data } = await Api.post(`/v3/organisations/${selected.id}/conversations/${conversationId}/messages`, payload);

    // Add message to chat
    return dispatch(addMessage(conversationId, data));
  });
};
